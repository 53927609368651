























































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import Company from "./monthly-costs/partials/Company.vue";
import Departments from "./monthly-costs/partials/Departments.vue";
import Teams from "./monthly-costs/partials/Teams.vue";
import Users from "./monthly-costs/partials/Users.vue";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import Cpqc from "./monthly-costs/partials/Cpqc.vue";
import PasswordProtected from "@/components/PasswordProtected.vue";

export default Vue.extend({
  components: { Company, Departments, Teams, Users, Cpqc, PasswordProtected },
  data() {
    return {
      self: this,
      month: new Date().getTime(),
      item: null,
      refreshing: true,
      tab: null,
      monthPickerOptions: {
        attrs: {
          label: "Chọn tháng",
        },
        content: {
          dpAttrs: {
            type: "month",
          },
          format: "YYYY-MM",
          setDefaultValue: true,
        },
      },
      importBtnOptions: {
        attrs: {
          small: true,
          color: "primary",
          dark: true,
          class: "ml-2 mb-4",
        },
        content: {
          text: "Import",
          icon: "mdi-upload",
        },
        target: {
          tooltip: { content: { text: "Import" } },
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            content: {
              toolbar: {
                title: "Import",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            file: {
                              type: "file",
                              attrs: {
                                label: "File",
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                save: {
                  content: {
                    text: "Tải lên",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      const btn = dialog.context();
                      const { month, refresh } = btn.context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      self.loading.value = true;
                      const result = await coreApiClient.call(
                        "monthlyCosts",
                        "importByMonth",
                        {
                          month,
                        },
                        data
                      );
                      self.loading.value = false;
                      if (result) {
                        dialog.hide();
                        refresh();
                      }
                    },
                  },
                },
              },
            },
          },
        },
      },
      editBtnOptions: {
        attrs: {
          small: true,
          color: "primary",
          dark: true,
          class: "ml-2 mb-4",
        },
        content: {
          icon: "mdi-cog-outline",
        },
        target: {
          tooltip: {
            content: {
              text: "Setting",
            },
          },
          dialog: {
            attrs: {
              width: "800px",
            },
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            content: {
              toolbar: {
                title: "Setting",
                subTitle(dialog) {
                  const btn = dialog.context();
                  const { item } = btn.context();
                  return item.name;
                },
              },
              content: {
                type: "XForm",
                makeAttrs(attrs: any = {}, dialog) {
                  const { item } = dialog.context().context();
                  attrs.xOptions = {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            exchangeRates: {
                              type: "XArrayInput",
                              attrs: {
                                label: "Thị trường",
                                xOptions: {
                                  content: {
                                    template: {
                                      formOptions: {
                                        content: {
                                          colLength: 4,
                                        },
                                      },
                                    },
                                    itemProperties: {
                                      marketId: {
                                        type: "XAutocomplete",
                                        attrs: {
                                          label: "Thị trường",
                                          "item-value": "_id",
                                          "item-text": "name",
                                          required: true,
                                          xOptions: {
                                            content: {
                                              async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("markets", "findAll", {
                                                  payload: JSON.stringify({
                                                    search,
                                                    limit,
                                                    filters: [
                                                      {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value: value && value._id,
                                                      },
                                                    ],
                                                  }),
                                                });
                                                return items;
                                              },
                                            },
                                          },
                                        },
                                      },
                                      value: {
                                        type: "number",
                                        attrs: {
                                          label: "Tỷ giá",
                                        },
                                      },
                                      mktCostPerRevenuePercentage: {
                                        type: "number",
                                        attrs: {
                                          label: "% CPQC/DS",
                                          suffix: `%`,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            userCommissionRateItems: {
                              type: "XArrayInput",
                              attrs: {
                                label: "Commission Rates",
                                xOptions: {
                                  content: {
                                    template: {
                                      formOptions: {
                                        content: {
                                          colLength: 6,
                                        },
                                      },
                                    },
                                    itemProperties: {
                                      userId: {
                                        type: "XAutocomplete",
                                        attrs: {
                                          label: "User",
                                          "item-value": "_id",
                                          "item-text": "name",
                                          required: true,
                                          xOptions: {
                                            content: {
                                              async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("users", "findAll", {
                                                  payload: JSON.stringify({
                                                    search,
                                                    limit,
                                                    filters: [
                                                      {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value: value && value._id,
                                                      },
                                                    ],
                                                  }),
                                                });
                                                return items;
                                              },
                                            },
                                          },
                                        },
                                      },
                                      value: {
                                        type: "number",
                                        attrs: {
                                          label: "Commission Rate",
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            sensitiveFeePercentage: {
                              type: "number",
                              attrs: {
                                label: "% giữ lại hàng sensitive X (General)",
                              },
                            },
                            sensitiveFeePercentageY: {
                              type: "number",
                              attrs: {
                                label: "% giữ lại hàng sensitive Y (General)",
                              },
                            },
                            sensitiveFeePercentageTmdt: {
                              type: "number",
                              attrs: {
                                label: "% giữ lại hàng sensitive X (TMDT)",
                              },
                            },
                            sensitiveFeePercentageYTmdt: {
                              type: "number",
                              attrs: {
                                label: "% giữ lại hàng sensitive Y (TMDT)",
                              },
                            },
                            feeBonus2RatioGeneral: {
                              type: "number",
                              attrs: {
                                label: "Thưởng chi phí công thức 2 General",
                              },
                            },
                            feeBonus2RatioTmdt: {
                              type: "number",
                              attrs: {
                                label: "Hệ số thưởng chi phí công thức 2 TMDT",
                              },
                            },
                            feeBonus3WarehouseRatioGeneral: {
                              type: "number",
                              attrs: {
                                label: "Hệ số thưởng chi phí công thức 3 chi phí kho General",
                              },
                            },
                            feeBonus3WarehouseRatioTmdt: {
                              type: "number",
                              attrs: {
                                label: "Hệ số thưởng chi phí công thức 3 chi phí kho TMDT",
                              },
                            },
                            feeBonus3OtherRatioGeneral: {
                              type: "number",
                              attrs: {
                                label: "Hệ số thưởng chi phí công thức 3 chi phí khác General",
                              },
                            },
                            feeBonus3OtherRatioTmdt: {
                              type: "number",
                              attrs: {
                                label: "Hệ số thưởng chi phí công thức 3 chi phí khác TMDT",
                              },
                            },
                          },
                        },
                      },
                    },
                  };
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer(item);
                  return attrs;
                },
              },
              buttons: {
                save: {
                  content: {
                    text: "Cập nhật",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      const btn = dialog.context();
                      const { item, refresh } = btn.context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      self.loading.value = true;
                      const result = await coreApiClient.call(
                        "monthlyCosts",
                        "update",
                        {
                          id: item._id,
                        },
                        {
                          data,
                        }
                      );
                      self.loading.value = false;
                      if (result) {
                        dialog.hide();
                      }
                      refresh();
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    totalValue() {
      if (!this.item) {
        return 0;
      }
      let value = this.item.items.reduce((sum, item) => {
        if (item.value) {
          sum += item.value;
        }
        return sum;
      }, 0);
      value +=
        this.item.userItems?.reduce((sum, userItem) => {
          if (userItem.psFee) {
            sum += userItem.psFee;
          }
          return sum;
        }, 0) || 0;
      return value;
    },
  },
  watch: {
    month() {
      this.refresh();
    },
  },
  methods: {
    async refresh() {
      this.refreshing = true;
      this.item = await coreApiClient.call(
        "monthlyCosts",
        "findByMonth",
        {
          month: this.month,
        },
        undefined,
        undefined,
        "viewProtectedPage2"
      );
      this.refreshing = false;
    },
    exportExcel() {},
  },
});
